import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import '../styles/Navbar.css';

const Navbar = () => {
    const { isLoggedIn, userRole, logout } = useAuth();
    const navigate = useNavigate();
    const dashboardLink = userRole === 'graduate' ? '/graduate-dashboard' : '/professor-dashboard';

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return (
        <nav className="navbar">
            <div className="nav-container">
                <Link to="/" className="nav-logo">Home</Link>
                <ul className="nav-menu">
                    <li className="nav-item">
                        <Link to="https://bxn9530.uta.cloud/" className="nav-link">Blog</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/services" className="nav-link">Services</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about-us" className="nav-link">About Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact-us" className="nav-link">Contact Us</Link>
                    </li>
                    {isLoggedIn ? (
                        <>
                            <li className="nav-item">
                                <Link to={dashboardLink} className="nav-link">Dashboard</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/profile" className="nav-link">Profile</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/messages" className="nav-link">Messages</Link>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link" onClick={handleLogout}>Log out</a>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="nav-item">
                                <Link to="/login" className="nav-link">Login</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/register" className="nav-link">Register</Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
