import React, { useState } from 'react';
import '../styles/Messages.css';

const Messages = () => {
    const [contacts, setContacts] = useState([
        { id: 1, name: 'Student 2', lastMessage: 'Hello, how are you?' },
        { id: 2, name: 'Professor 1', lastMessage: 'Are you there?' },
    ]);

    const [selectedContact, setSelectedContact] = useState(null);
    const [message, setMessage] = useState('');

    const handleSelectContact = (contact) => {
        setSelectedContact(contact);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = () => {
        console.log(`Sending message to ${selectedContact.name}: ${message}`);
        setMessage('');
    };

    return (
        <div className="messages-page">
            <div className="contacts-list">
                {contacts.map(contact => (
                    <div key={contact.id} className="contact" onClick={() => handleSelectContact(contact)}>
                        <h3>{contact.name}</h3>
                        <p>{contact.lastMessage}</p>
                    </div>
                ))}
            </div>
            <div className="chat-window">
                {selectedContact ? (
                    <>
                        <h2>{selectedContact.name}</h2>
                        <div className="chat-messages">
                        </div>
                        <div className="message-input">
                            <input type="text" value={message} onChange={handleMessageChange} />
                            <button onClick={handleSendMessage}>Send</button>
                        </div>
                    </>
                ) : (
                    <p>Select a contact to start chatting</p>
                )}
            </div>
        </div>
    );
};

export default Messages;


