import React from 'react';
import '../styles/Homepage.css';

const Homepage = () => {
    return (
        <div className="homepage">

            <header className="header">
                <h1>Welcome to the Volunteer Program for MS Graduates</h1>
                <p>Empowering international students through meaningful volunteer opportunities.</p>
            </header>
            <section className="intro">
                <h2>About the Program</h2>
                <p>In response to economic challenges, our government-designed volunteer program offers MS graduates in Computer Science a platform to gain practical experience, contribute to research and implementation projects, and stay compliant with immigration requirements.</p>
            </section>
            <section className="how-it-works">
                <h2>How It Works</h2>
                <div className="steps">
                    <div className="step">
                        <h3>1. Register</h3>
                        <p>Sign up using your university email and complete the verification process.</p>
                    </div>
                    <div className="step">
                        <h3>2. Get Assigned</h3>
                        <p>Professors will assign tasks that align with your skills and interests.</p>
                    </div>
                    <div className="step">
                        <h3>3. Contribute</h3>
                        <p>Engage in research or implementation projects for 21 hours weekly.</p>
                    </div>
                    <div className="step">
                        <h3>4. Report & Grow</h3>
                        <p>Submit weekly reports on your progress and receive valuable feedback.</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Homepage;
