import React from 'react';
import '../styles/Services.css';

const Services = () => {
    return (
        <div className="services">
            <h1>Our Services</h1>
            <div className="service-list">
                <div className="service-item">
                    <h2>Volunteer Opportunities</h2>
                    <p>Engage in meaningful volunteer work that aligns with your academic and professional interests.</p>
                </div>
                <div className="service-item">
                    <h2>Professional Development</h2>
                    <p>Gain practical experience and develop essential skills through hands-on projects and tasks.</p>
                </div>
                <div className="service-item">
                    <h2>Mentorship and Guidance</h2>
                    <p>Receive mentorship and guidance from experienced professors and industry professionals.</p>
                </div>
                <div className="service-item">
                    <h2>Networking Opportunities</h2>
                    <p>Connect with peers, professionals, and organizations to expand your professional network.</p>
                </div>
                <div className="service-item">
                    <h2>AI Content Detection</h2>
                    <p>Verify the authenticity of content and detect if it is AI-generated to ensure originality and credibility.</p>
                </div>

            </div>
        </div>
    );
};

export default Services;
