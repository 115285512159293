import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Aboutus from './pages/Aboutus';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Contactus from './pages/Contactus';
import GraduateDashboard from './pages/GraduateDashboard';
import ProfessorDashboard from './pages/ProfessorDashboard';
import LoginPage from './pages/Login';
import { AuthProvider } from './AuthContext';
import ProfilePage from './pages/Profile';
import RegisterPage from './pages/Register';
import Messages from './pages/Messages';
import Services from './pages/Services';


const App = () => {
  return (
    <Router>
      <AuthProvider>

        <div>
          <Navbar />
          <div className="content-wrapper">
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/about-us" element={<Aboutus />} />
              <Route path="/contact-us" element={<Contactus />} />
              <Route path="/graduate-dashboard" element={<GraduateDashboard />} />
              <Route path="/professor-dashboard" element={<ProfessorDashboard />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/services" element={<Services />} />

            </Routes>

            <Footer />
          </div>
        </div>
      </AuthProvider>

    </Router>
  );
};

export default App;
