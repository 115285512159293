import React from 'react';
import '../styles/Aboutus.css';

const Aboutus = () => {
    return (
        <div className="aboutus">
            <h1>About Us</h1>
            <p>The Volunteer Program for MS Graduates in Computer Science is an initiative designed to support international students during challenging economic times. Our program provides a platform for students to engage in meaningful volunteer work, gain practical experience, and contribute to impactful projects under the guidance of experienced professors.</p>
            <p>Our mission is to empower students with the skills and knowledge they need to excel in their careers while giving back to the community. We believe in fostering a collaborative environment where students can learn, grow, and make a difference.</p>
            <p>Through our program, students have the opportunity to work on a variety of tasks, ranging from research to implementation, all while adhering to the highest standards of professionalism and integrity. We are committed to providing a supportive and enriching experience for all our participants.</p>
        </div>
    );
};

export default Aboutus;
