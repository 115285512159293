import React, { useState } from 'react';
import '../styles/Profile.css';

const ProfilePage = () => {
    const [profile, setProfile] = useState({
        name: 'Student',
        email: 'grad@example.com',
        phone: '+1 234 567 890',
        major: 'Computer Science',
        graduationDate: 'May 2024',
        volunteerProject: 'Web Development for Nonprofits',
        hoursCommitted: '21 hours/week',
        supervisor: 'Prof. XYZ',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };

    return (
        <div className="profile-page">
            <h1>Edit Profile</h1>
            <div className="profile-form">
                <label>
                    Name:
                    <input
                        type="text"
                        name="name"
                        value={profile.name}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={profile.email}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Phone:
                    <input
                        type="tel"
                        name="phone"
                        value={profile.phone}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Major:
                    <input
                        type="text"
                        name="major"
                        value={profile.major}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Graduation Date:
                    <input
                        type="text"
                        name="graduationDate"
                        value={profile.graduationDate}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Volunteer Project:
                    <input
                        type="text"
                        name="volunteerProject"
                        value={profile.volunteerProject}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Hours Committed:
                    <input
                        type="text"
                        name="hoursCommitted"
                        value={profile.hoursCommitted}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Supervisor:
                    <input
                        type="text"
                        name="supervisor"
                        value={profile.supervisor}
                        onChange={handleChange}
                    />
                </label>
                <button type="submit">Save Changes</button>
            </div>
        </div>
    );
};

export default ProfilePage;
