import React, { useState } from 'react';
import '../styles/GraduateDashboard.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


const GraduateDashboard = () => {
    const [tasks, setTasks] = useState([
        { id: 1, title: 'Research on AI', deadline: 'March 10, 2024', completed: false },
        { id: 2, title: 'Develop a prototype', deadline: 'April 5, 2024', completed: false },
        { id: 3, title: 'Write a report', deadline: 'April 20, 2024', completed: false },
    ]);


    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const handleCompleteTask = (taskId) => {
        setTasks(tasks.map(task => task.id === taskId ? { ...task, completed: !task.completed } : task));
    };

    const handleSubmitReport = (event) => {
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            // Add your logic here for handling the report submission, e.g., sending data to a server
            alert('Report submitted successfully!');
        }, 2000);
    };

    return (
        <div className="dashboard">
            <h1>Graduate Dashboard</h1>
            <div className="progress-overview">
                <h2>Progress Overview</h2>
                <p>Completed Tasks: {tasks.filter(task => task.completed).length}/{tasks.length}</p>
                <p>Hours Logged: 105/210</p>
            </div>
            <div className="calendar-section">
                <h2>Calendar</h2>
                <Calendar
                    onChange={setDate}
                    value={date}
                    tileClassName={({ date, view }) => {
                        if (view === 'month' && tasks.find(task => new Date(task.deadline).toDateString() === date.toDateString())) {
                            return 'deadline';
                        }
                    }}

                />
                <p>Selected Date: {date.toDateString()}</p>
            </div>
            <div className="assigned-tasks">
                <h2>Assigned Tasks</h2>
                <ul>
                    {tasks.map(task => (
                        <li key={task.id}>
                            {task.title} (Deadline: {task.deadline})
                            <button onClick={() => handleCompleteTask(task.id)}>
                                {task.completed ? 'Mark as Incomplete' : 'Mark as Complete'}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="weekly-reports">
                <h2>Weekly Reports</h2>
                {loading ? (
                    <div>Checking for AI, please wait</div>
                ) : (<form onSubmit={handleSubmitReport}>
                    <label htmlFor="report-summary">Report Summary:</label>
                    <textarea id="report-summary" rows="4"></textarea>
                    <label htmlFor="hours-justification">Justification for 21 Hours:</label>
                    <textarea id="hours-justification" rows="2"></textarea>
                    <label htmlFor="attachments">Upload Attachments:</label>
                    <input type="file" id="attachments" multiple />
                    <button type="submit">Submit Report</button>
                </form>
                )}

            </div>

        </div>
    );
};

export default GraduateDashboard;
