import React from 'react';
import '../styles/Contactus.css';

const Contactus = () => {
    return (
        <div className="contactus">
            <h1>Contact Us</h1>
            <p>If you have any questions or would like to get in touch with our team, please feel free to contact us using the information below:</p>
            <div className="contact-info">
                <p><strong>Email:</strong> support@volunteerprogram.com</p>
                <p><strong>Phone:</strong> +1 (123) 456-7890</p>
                <p><strong>Address:</strong> 123 Road, University, 12345</p>
            </div>
        </div>
    );
};

export default Contactus;
