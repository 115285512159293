import React, { useState } from 'react';
import { Link } from 'react-scroll';
import '../styles/ProfessorDashboard.css';

const ProfessorDashboard = () => {
    const [newTask, setNewTask] = useState({
        title: '',
        deadline: '',
        priority: '',
        assignedStudent: '',
    });

    const [students, setStudents] = useState([
        { id: 1, name: 'Student 1' },
        { id: 2, name: 'Student 2' },
        // Add more students as needed
    ]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTask({ ...newTask, [name]: value });
    };

    const handleAssignTask = () => {
        // Submit the new task to the backend and update the state
        console.log('Assigning task:', newTask);
        // Reset the form
        setNewTask({ title: '', deadline: '', priority: '', assignedStudent: '' });
    };

    const [reports, setReports] = useState([
        { id: 1, student: 'Student 1', summary: 'Completed research on AI.', hours: 21, feedback: '' },
        { id: 2, student: 'Student 2', summary: 'Developed a prototype.', hours: 21, feedback: '' },
    ]);

    const handleFeedbackChange = (id, feedback) => {
        setReports(reports.map(report => report.id === id ? { ...report, feedback } : report));
    };

    const handleSubmitFeedback = (id) => {
        console.log(`Feedback for report ${id}: ${reports.find(report => report.id === id).feedback}`);
    };

    const [assignedTasks, setAssignedTasks] = useState([
        { id: 1, title: 'Research AI algorithms', deadline: '2024-03-10', priority: 'High', student: 'Student 1' },
        { id: 2, title: 'Develop prototype', deadline: '2024-03-15', priority: 'Medium', student: 'Student 2' },
    ]);

    return (
        <div className="professor-dashboard">
            <h1>Professor Dashboard</h1>
            <div className="navigation-buttons">
                <Link to="review-reports" smooth={true} duration={500}>Review Reports</Link>
                <Link to="assign-task" smooth={true} duration={500}>Assign Task</Link>
                <Link to="view-assigned-tasks" smooth={true} duration={500}>View Assigned Tasks</Link>
                <Link to="view-weekly-reports" smooth={true} duration={500}>View Weekly Reports</Link>
            </div>
            <div className="report-review" id="review-reports">
                <h2>Review Reports</h2>
                <div className="reports">
                    {reports.map(report => (
                        <div key={report.id} className="report">
                            <div className="report-header">
                                <h3>{report.student}</h3>
                                <span className="hours">{report.hours} hours</span>
                            </div>
                            <p className="summary">Summary: {report.summary}</p>
                            <textarea
                                className="feedback"
                                placeholder="Provide feedback"
                                value={report.feedback}
                                onChange={(e) => handleFeedbackChange(report.id, e.target.value)}
                            ></textarea>
                            <button onClick={() => handleSubmitFeedback(report.id)}>Submit Feedback</button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="task-assignment" id="assign-task">
                <h2>Assign Task</h2>
                <form>
                    <label htmlFor="task-title">Task Title:</label>
                    <input
                        type="text"
                        id="task-title"
                        name="title"
                        value={newTask.title}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="task-deadline">Deadline:</label>
                    <input
                        type="date"
                        id="task-deadline"
                        name="deadline"
                        value={newTask.deadline}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="task-priority">Priority:</label>
                    <select
                        id="task-priority"
                        name="priority"
                        value={newTask.priority}
                        onChange={handleInputChange}
                    >
                        <option value="">Select Priority</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                    </select>
                    <label htmlFor="task-student">Assigned Student:</label>
                    <select
                        id="task-student"
                        name="assignedStudent"
                        value={newTask.assignedStudent}
                        onChange={handleInputChange}
                    >
                        <option value="">Select Student</option>
                        {students.map(student => (
                            <option key={student.id} value={student.id}>{student.name}</option>
                        ))}
                    </select>
                    <button type="button" onClick={handleAssignTask}>Assign</button>
                </form>
            </div>

            <div id="view-assigned-tasks">
                <h2>View Assigned Tasks</h2>
                <div className="assigned-tasks">
                    {assignedTasks.map(task => (
                        <div key={task.id} className="task-card">
                            <div className="task-header">
                                <h3>{task.title}</h3>
                                <span className={`priority ${task.priority.toLowerCase()}`}>{task.priority}</span>
                            </div>
                            <div className="task-body">
                                <p><strong>Deadline:</strong> {task.deadline}</p>
                                <p><strong>Assigned to:</strong> {task.student}</p>
                            </div>
                            <div className="task-footer">
                                <button className="complete-btn">Mark as Completed</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            <div id="view-weekly-reports">
                <h2>View Weekly Reports</h2>
                <div className="weekly-reports">
                    {reports.map(report => (
                        <div key={report.id} className="report">
                            <h3>{report.student}</h3>
                            <p>Summary: {report.summary}</p>
                            <p>Hours: {report.hours}</p>
                            <p>Feedback: {report.feedback}</p>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default ProfessorDashboard;
